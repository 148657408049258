var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"bank-account"},[_c('v-card-title',[_vm._v(" How would you like to receive donations? ")]),_c('v-card-text',[_c('v-radio-group',{model:{value:(_vm.donationSelection),callback:function ($$v) {_vm.donationSelection=$$v},expression:"donationSelection"}},_vm._l((_vm.radioOptions),function(option,i){return _c('v-radio',{key:i,staticStyle:{"margin-bottom":"25px"},attrs:{"value":i,"on-icon":"fas fa-dot-circle"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_c('p',{staticStyle:{"width":"100% !important"}},[_c('strong',[_vm._v(_vm._s(option.radioText))]),_c('br'),_vm._v(" "+_vm._s(option.radioDescription)+" ")]),_c('br'),(
								option.value === 'paypal' &&
									_vm.donationSelection === 1
							)?_c('div',{staticClass:"paypal-input"},[_c('v-text-field',{attrs:{"placeholder":"PayPal Username","label":"PayPal Username","prefix":"@"},model:{value:(_vm.paypalAccount),callback:function ($$v) {_vm.paypalAccount=$$v},expression:"paypalAccount"}})],1):_vm._e()])]},proxy:true}],null,true)})}),1),(_vm.donationSelection == 0)?_c('div',{staticClass:"stripe-information"},[_c('p',[_vm._v(" We use Stripe to make sure you get paid on time and to keep your personal bank details secure. Click Link Account to set up your payments on Stripe. ")])]):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('Roundup-Button',{style:({
				'margin-top': '0px',
				float: 'right',
				marginBottom: '20px',
				position: 'sticky'
			}),attrs:{"disabled":_vm.isLoading,"loading":_vm.isLoading,"color":"primary"},on:{"click":_vm.radioActionButton}},[_vm._v(_vm._s(_vm.getButtonText))]),_c('v-spacer')],1),_c('v-dialog',{attrs:{"max-width":"350px"},model:{value:(_vm.showPaperModal),callback:function ($$v) {_vm.showPaperModal=$$v},expression:"showPaperModal"}},[_c('v-card',{staticStyle:{"max-width":"350px !important"}},[_c('v-card-title',[_vm._v(" Confirm Grant Check ")]),_c('v-card-text',[_c('p',{staticStyle:{"padding":"0px 0px 0px 0px"}},[_vm._v(" Monthly donations processed through our DAF will be issued via a grant check and can take up to 8 weeks to receive. ")])]),_c('v-card-actions',[_c('Roundup-Button',{style:({
						border: '1px solid #184fd5',
						'margin-bottom': '20px',
						'margin-left': '20px',
						float: 'left'
					}),attrs:{"color":"white"},on:{"click":_vm.closeModal}},[_vm._v("Back")]),_c('v-spacer'),_c('Roundup-Button',{style:({
						'margin-bottom': '20px',
						float: 'left'
					}),attrs:{"color":"primary"},on:{"click":_vm.confirmedPaperCheck}},[_vm._v("Confirm")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }