<template>
	<v-card class="bank-account">
		<v-card-title>
			How would you like to receive donations?
		</v-card-title>
		<v-card-text>
			<v-radio-group v-model="donationSelection">
				<v-radio
					:value="i"
					on-icon="fas fa-dot-circle"
					style="margin-bottom: 25px"
					v-for="(option, i) in radioOptions"
					:key="i"
				>
					<template v-slot:label>
						<div>
							<p style="width: 100% !important">
								<strong>{{ option.radioText }}</strong
								><br />
								{{ option.radioDescription }}
							</p>
							<br />
							<div
								class="paypal-input"
								v-if="
									option.value === 'paypal' &&
										donationSelection === 1
								"
							>
								<v-text-field
									placeholder="PayPal Username"
									label="PayPal Username"
									v-model="paypalAccount"
									prefix="@"
								></v-text-field>
							</div>
						</div>
					</template>
				</v-radio>
			</v-radio-group>
			<div class="stripe-information" v-if="donationSelection == 0">
				<p>
					We use Stripe to make sure you get paid on time and to keep
					your personal bank details secure. Click Link Account to set
					up your payments on Stripe.
				</p>
			</div>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<Roundup-Button
				:disabled="isLoading"
				:loading="isLoading"
				@click="radioActionButton"
				color="primary"
				v-bind:style="{
					'margin-top': '0px',
					float: 'right',
					marginBottom: '20px',
					position: 'sticky'
				}"
				>{{ getButtonText }}</Roundup-Button
			>
			<v-spacer></v-spacer>
		</v-card-actions>
		<v-dialog v-model="showPaperModal" max-width="350px">
			<v-card style="max-width: 350px !important">
				<v-card-title>
					Confirm Grant Check
				</v-card-title>
				<v-card-text>
					<p style="padding: 0px 0px 0px 0px">
						Monthly donations processed through our DAF will be
						issued via a grant check and can take up to 8 weeks to
						receive.
					</p>
				</v-card-text>
				<v-card-actions>
					<Roundup-Button
						@click="closeModal"
						color="white"
						v-bind:style="{
							border: '1px solid #184fd5',
							'margin-bottom': '20px',
							'margin-left': '20px',
							float: 'left'
						}"
						>Back</Roundup-Button
					>
					<v-spacer></v-spacer>
					<Roundup-Button
						@click="confirmedPaperCheck"
						color="primary"
						v-bind:style="{
							'margin-bottom': '20px',
							float: 'left'
						}"
						>Confirm</Roundup-Button
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-card>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import table from '@/mixins/table'
import Screen from '../../ui/Screen'
import UiButton from '../../ui/Button'
import UiLabel from '../../ui/Label'
import UiInput from '../../ui/Input'
import Modal from '../../ui/Modal'

export default {
	name: 'bank-account',
	components: { UiButton, UiInput, UiLabel, Screen, Modal },
	mixins: [table],
	props: {
		uuid: String
	},
	data() {
		return {
			isLoading: false,
			stripeAccounts: process.env.VUE_APP_stripeFallbackAccounts.split(
				','
			),
			waitingOnStripe: false,
			stripeErrors: [],
			donationSelection: 0,
			StripeAccount: null,
			showPaperModal: false,
			paypalAccount: null,
			radioOptions: [
				{
					radioText: 'Link Stripe Account',
					radioDescription:
						'Link a Stripe account to receive monthly donations directly via your Stripe account.',
					buttonText: 'Link Account',
					popupText: '',
					popupEnabled: true,
					value: 'login'
				},
				{
					radioText: 'Receive via PayPal (BETA)',
					radioDescription:
						"Enter your nonprofit's PayPal username to receive monthly donations directly via your PayPal account.",
					buttonText: 'Finish',
					popupText: '',
					popupEnabled: true,
					value: 'paypal'
				},
				{
					radioText: 'Wait for a Check',
					radioDescription:
						'If you do not link an account, donations will be processed through our DAF, and a grant will be issued via check. Allow extra time for the processing and mailing with this option.',
					buttonText: 'Finish',
					popupText: '',
					popupEnabled: true,
					value: 'other'
				}
			]
		}
	},
	computed: {
		...mapGetters(['hasStripe', 'stripeAccount', 'institutionUuid']),
		getButtonText() {
			return this.radioOptions[this.donationSelection].buttonText
		},
		...mapState({
			stripe_details: ({ stripeActions }) => stripeActions,
			item: ({ institution }) => institution.item,
			items: ({ institutionList }) => institutionList.items,
			total: ({ institutionList }) =>
				institutionList.items && institutionList.items.length
		})
	},
	mounted() {
		this.checkToSeeIfAlreadySetup()
	},
	methods: {
		...mapActions([
			'getCurrentUser',
			'getStripeExpressLink',
			'setPaperCheck',
			'setPaypalUsername'
		]),
		closeModal() {
			this.showPaperModal = false
		},
		linkStripe() {
			window.location.href =
				process.env.VUE_APP_stripeUrl + '&state=' + this.institutionUuid
		},
		async checkToSeeIfAlreadySetup() {
			await this.getCurrentUser()
			if (this.hasStripe) {
				this.$router.push({ name: 'brand' })
			}
		},
		async confirmedPaperCheck() {
			this.isLoading = true
			this.showPaperModal = false
			await this.setPaperCheck()
			await this.checkToSeeIfAlreadySetup()
			this.isLoading = false
		},
		async confirmPaypal() {
			this.isLoading = true
			this.showPaperModal = false
			await this.setPaypalUsername(this.paypalAccount)
			await this.checkToSeeIfAlreadySetup()
			this.isLoading = false
		},
		async radioActionButton() {
			this.isLoading = true
			if (this.donationSelection === 'express') {
				this.waitingOnStripe = true

				await this.getStripeExpressLink()
				if (this.stripe_details.success) {
					window.location.href = this.stripe_details.url
				}
			}
			if (
				this.radioOptions[this.donationSelection]['value'] === 'paypal'
			) {
				this.isLoading = true
				this.confirmPaypal()
			}
			if (
				this.radioOptions[this.donationSelection]['value'] === 'login'
			) {
				this.linkStripe()
			}
			if (
				this.radioOptions[this.donationSelection]['value'] === 'other'
			) {
				this.isLoading = false
				this.showPaperModal = true
			}
		}
	}
}
</script>

<style lang="scss">
@import '../../../styles/variables';

.bank-account {
	min-width: 460px !important;
	max-width: 720px !important;

	margin: auto !important;
	.radio_options {
		color: #484a50;
		font-weight: bold;
		margin-left: 10px;
	}
	.add-bank {
		position: relative;
		margin: 40px 0px 40px 0px;
		min-height: 300px;
		span {
			&::before {
				content: 'Recommended';
				padding: 0px 10px 0px 10px;
				margin-left: 30px;
				background-color: #d9fdf5;
				line-height: 1.25;
				color: #07745c;
			}
		}
	}
	p {
		font-size: 16px;
		padding-left: 20px;
		color: $roundup-charcoal !important;
	}
	.roundup-toggle-option {
		margin-top: 20px;
		border: 0px;
		width: 100%;
		input[type='radio'] {
			width: 20px;
			height: 20px;
		}
	}
	.stripe-information {
		padding: 10px 135px 10px 10px;
		background: url('../../../assets/stripe_slate_sm.png') right 25px center
			no-repeat;
		background-size: 80px auto;
		background-color: #d8feff;
	}
	.attention_call_20 {
		margin-top: 40px;
		margin-bottom: 40px;
		color: #484a50;
		font-size: 20px;
		font-weight: bold;
	}
	.stripe-box {
		background-color: #d8feff;
		margin-top: 20px;
		min-width: 400px;
		.stripe-text {
			background-color: #d8feff;
			padding: 20px 20px 20px 20px;
			font-size: 16px;
			color: #484a50;
			max-width: 70%;
		}
		.stripe-logo {
			float: left;
			position: relative;
			background: url('../../../assets/stripe_slate_sm.png') right center;
			background-repeat: no-repeat;
			width: 100%;
			height: 51px;
		}
	}
	.paypal-input {
		width: 200px !important;
		display: block;
		padding-left: 20px !important;
	}
}
</style>
