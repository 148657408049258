// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/stripe_slate_sm.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".bank-account{min-width:460px!important;max-width:720px!important;margin:auto!important}.bank-account .radio_options{color:#484a50;font-weight:700;margin-left:10px}.bank-account .add-bank{position:relative;margin:40px 0 40px 0;min-height:300px}.bank-account .add-bank span:before{content:\"Recommended\";padding:0 10px 0 10px;margin-left:30px;background-color:#d9fdf5;line-height:1.25;color:#07745c}.bank-account p{font-size:16px;padding-left:20px;color:#484a50!important}.bank-account .roundup-toggle-option{margin-top:20px;border:0;width:100%}.bank-account .roundup-toggle-option input[type=radio]{width:20px;height:20px}.bank-account .stripe-information{padding:10px 135px 10px 10px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") right 25px center no-repeat;background-size:80px auto;background-color:#d8feff}.bank-account .attention_call_20{margin-top:40px;margin-bottom:40px;color:#484a50;font-size:20px;font-weight:700}.bank-account .stripe-box{background-color:#d8feff;margin-top:20px;min-width:400px}.bank-account .stripe-box .stripe-text{background-color:#d8feff;padding:20px 20px 20px 20px;font-size:16px;color:#484a50;max-width:70%}.bank-account .stripe-box .stripe-logo{float:left;position:relative;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 100%;background-repeat:no-repeat;width:100%;height:51px}.bank-account .paypal-input{width:200px!important;display:block;padding-left:20px!important}", ""]);
// Exports
module.exports = exports;
